<template>
  <v-container class="view-attendees__blocked">
    <!-- pageHeader -->
    <v-row align="center" class="mb-4" dense>
      <v-col cols="12" md="8">
        <PageTitle>Asistentes Bloqueados</PageTitle>
      </v-col>
      <v-col cols="12" md="4" class="text-end">
        <v-btn :block="appMobile" color="primary" class="px-6" large @click="dialog = true" depressed>
          Agregar Asistente
        </v-btn>
      </v-col>
    </v-row>

    <!-- loading -->
    <div v-if="loading"> <v-skeleton-loader v-for="n in 10" :key="n" type="list-item-avatar-two-line" class="mb-3" />
    </div>

    <v-subheader>
      <span style="width: 15px;">{{ selectedCount }}</span>Asistentes seleccionados
      <v-spacer />
      <v-btn color="primary" @click="selectedAttendees = []" text small>Limpiar Selección</v-btn>
    </v-subheader>

    <v-card v-for="(item, index) in attendees" :key="index" class="mb-3" flat>
      <v-list-item>
        <v-list-item-action>
          <v-checkbox v-model="selectedAttendees" :value="item" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{ item }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <div class="text-right">
      <v-btn color="error" @click="removeData" :disabled="selectedAttendees.length === 0" :loading="loading"
        depressed>
        Eliminar
      </v-btn>
    </div>

    <!-- dialog: new -->
    <dialog-searchUser v-model="dialog" @result="onResult" />
  </v-container>
</template>

<script>
import { getAllBlockedAttendees, removeBlackList } from "@/api/attendees"
import DialogSearchUser from "@/components/attendees/AddBlackList.vue";
import PageTitle from '@/components/layout/PageTitle.vue';

export default {
  components: { DialogSearchUser, PageTitle },

  data: () => ({
    dialog: false,
    loading: false,
    attendees: [],
    selectedAttendees: []
  }),

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },

    selectedCount() {
      return this.selectedAttendees.length;
    }
  },

  methods: {
    async fetchData() {
      try {
        this.loading = true;
        this.attendees = await getAllBlockedAttendees();
      } catch (error) {
        console.error('blacklist: ', error)
      } finally {
        this.loading = false;
      }
    },

    async removeData() {
      try {
        this.loading = true;
        await removeBlackList(this.selectedAttendees);
        this.fetchData();
      } catch (error) {
        console.error('blacklist:  ', error)
      } finally {
        this.loading = false;
        this.selectedAttendees = []
      }
    },

    onResult(params) {
      this.attendees.push(params)
    },
  },

  mounted() {
    this.fetchData();
  }
}
</script>